import React, { FunctionComponent } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "src/lib/utils";

const headerVariants = cva(
  "w-full min-h-[220px] flex flex-col py-10 px-24 box-border items-center justify-center text-left text-4xl text-slate-500 font-text-xl-font-normal sm:min-h-36 sm:text-3xl xs:text-2xl xs:px-6",
  {
    variants: {
      background: {
        default: "bg-slate-100",
        primary_gradient: "bg-primary-gradient-to-r", // replace with your desired background
        light_gradient: "bg-light-gradient",
      },
      padding: {
        default: "py-10 px-24 sm:px-14",
      },
    },
    defaultVariants: {
      background: "default",
      padding: "default",
    },
  }
);

const maxWidthVariants = cva(
  "w-full flex flex-col items-start justify-start gap-2 ",
  {
    variants: {
      maxWidth: {
        default: "max-w-[1200px]",
        narrow: "max-w-[768px]", // replace with your desired max-width
      },
    },
    defaultVariants: {
      maxWidth: "default",
    },
  }
);

interface HeaderProps
  extends VariantProps<typeof headerVariants>,
    VariantProps<typeof maxWidthVariants> {
  title: string;
  subtitle?: string;
}

const PageHeader: FunctionComponent<HeaderProps> = ({
  title,
  subtitle,
  background,
  padding,
  maxWidth,
}) => {
  return (
    <div className={cn(headerVariants({ background, padding }))}>
      <div className={cn(maxWidthVariants({ maxWidth }))}>
        <b className="flex relative leading-[150%] text-gradient">{title}</b>
        {subtitle && (
          <div className="relative text-xl leading-[150%] flex flex-col items-start w-full h-auto shrink-0 md:text-base">
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
